import React from 'react'
import SEO from '~components/Common/SEO'
import page from './page'

const PageTemplate = ({ pageContext }) => {
	const Page = page()

	const { metadata } = pageContext

	return (
		<>
			<SEO
				title={metadata.title}
				description={metadata.description}
				ogTitle={metadata.ogTitle}
				ogDescription={metadata.ogDescription}
				ogImage={metadata.ogImage}
				// ogUrl={`${pageHref}/${slug}`}
				// canonical={`${pageHref}/${slug}`}
			/>

			<Page metadata={pageContext} />
		</>
	)
}
export default PageTemplate
